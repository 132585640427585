import {   Link } from "gatsby";
import React from "react";
import PropTypes from "prop-types";


import Call from "../static/svg/icon-call.inline.svg";
import Globe from "../static/svg/icon-globe.inline.svg";
import PenLogo from "../static/svg/pen-logo.inline.svg";

function HeaderEn( props ) {

  const { langlink, tel } = props;

  return (

    <div className="flex flex-row mx-auto hidden md:flex max-w-screen-2xl ">
      <div className="flex-auto text-left p-2 text-sm">
        <p className="mb-0">Your Osteopath in Nishiogikubo, Suginami-ku, Tokyo</p>
        <Link to='/en/'>
          <div className="text-2xl"><span className="inline-block mt-2">

            <PenLogo className="inline-block mr-1 -mt-1.5 width='45px' height='45px'" />
            <span className="text-xl tracking-tight ml-1.5 mr-0.5 pt-2 skip-b align-text-bottom font-semibold">
              Penguin-dou Osteopathy</span>

          </span></div>
        </Link>

        <p className="mb-0"><span className="xl:text-lg">5 minutes from Nishiogikubo sta.&nbsp;&#40;JB03&#41; on JR Chuo/Sobu line</span></p>



      </div>
      <div className="flex-auto text-right p-2">
        <p>
          <Call className="h-6 w-6 fill-current  inline-block mr-0.5 align-bottom" />
          <span className="font-bold text-2xl">{tel}</span>
        </p>
        <p className="leading-none"><span className="text-xs mt-2 mr-4 ">telephone reception hours：<br />
        </span>
          <span className="text-sm">Mon to Sat 10:00~18:00</span>
        </p>
        <Link to={langlink} className="pt-3 underline text-blue-600 hover-hover:hover:text-blue-800 visited:text-purple-600">
          <Globe className="w-6 h-6  inline-block mr-1 text-xl align-text-bottom" />

          <span className="text-lg">日本語</span>
        </Link>

      </div>

    </div>



  );
}


HeaderEn.defaultProps = {
  langlink: '/404/',
  tel: '',
};

HeaderEn.propTypes = {
  langlink: PropTypes.string,
  tel: PropTypes.string,
};

export default HeaderEn;