import React from 'react'
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

import ChevronDoubleRight from "../static/svg/icon-chevron-double-right.inline.svg";

function VoiceShort ( { isShowButton } ) {

  return (
    <div className="">

{ (isShowButton ) ?
      <h2 className="mt-0 mb-6  mx-0 p-1 text-center text-c-base bg-c-main">お客様の声をご紹介</h2>
      :
      <h2 className="mt-0 mb-3  mx-0 p-1  text-c-base bg-c-main">お客様の声をご紹介</h2>
}
      <div className="mx-2 p-2 py-4 bg-c-base border-c-acc border-dotted border-2 ">


      <div className="overflow-hidden">
      <StaticImage
              src="../images/womanface2.png"
              alt="女性のお客様"
              placeholder="blurred"
              layout="constrained"
              width={50}
              className="float-left mr-2"
            />
        <h3><span className="c-marker">慢性的な肩こり・頭痛</span></h3><p className="my-0.5">山畑 由紀江様/50代</p>
      </div>


      <div className="overflow-hidden">


        <p>施術の後、ベッドから立った時に、”体が軽い”とびっくりしました。</p>
      </div>
           
      <hr className="topix-border" />

        <div className="overflow-hidden">
        <StaticImage
              src="../images/womanface3.png"
              alt="女性のお客様"
              placeholder="blurred"
              layout="constrained"
              width={50}
              className="float-left mr-2"
            />

        <h3><span className="c-marker">腰痛、右アキレス腱あたりの痛み</span></h3><p className="my-0.5">M.N.様/50代</p>
      </div>

      <div className="overflow-hidden">


        <p>とても丁寧にみていただき、ひとつひとつ細やかに説明してもらったので安心して施術を受けることができました。</p>
      </div>

      <hr className="topix-border" />

      <div className="overflow-hidden">
      <StaticImage
              src="../images/womanface1.png"
              alt="女性のお客様"
              placeholder="blurred"
              layout="constrained"
              width={50}
              className="float-left mr-2"
            />
       <h3><span className="c-marker">腰痛・肩こり・首の痛み</span></h3><p className="my-0.5">Jasmine様/38歳</p>
      </div>

        <div className="overflow-hidden">
         
          <p>
          注意いただいたポイントを気を付けていると生活しやすくなりました！ありがとうございます！！
            </p>

        </div>
        
      
      
      <hr className="topix-border" />

      
      { (isShowButton ) ?
            <Link to='/voices/'  className="text-lg underline text-blue-800 hover-hover:hover:text-blue-900">
        <button type='button' className="bg-c-main text-c-base hover-hover:hover:bg-c-acc  text-center my-1.5 py-1.5 px-4 rounded font-semibold shadow-lg">


          お客様の声をもっと見る
          <ChevronDoubleRight className="ml-2.5 w-6 h-6 align-middle inline  text-2lx pb-0.5" />

        </button>
      </Link>
      : null
}
      </div>
    </div>
  )
};

VoiceShort.defaultProps = {
  isShowButton: true,
};


export default VoiceShort



