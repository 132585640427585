import React from 'react'
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

import ArrowI from "../static/svg/icon-arrow.inline.svg";

function ForBeginner (  ) {

  return (
    <>


      <div className="my-5 py-2 bg-slate-500">
        <h2 className="text-center text-white py-2 font-bold">初めての方へ</h2>
        <div className="flex flex-row flex-wrap justify-evenly">

          <div className="basis-1/2 sm:basis-1/3 xl:basis-1/6 max-w-xs">
            <div className="m-1.5">
              <Link to='/profile/'>
                <button type='button' className="bg-white hover-hover:hover:bg-slate-400  text-left rounded font-semibold shadow-lg">

                  <StaticImage
                    src="../images/profile2.jpg"
                    alt="当院のオステオパシー施術の様子"
                    placeholder="blurred"
                    layout="constrained"
                    className=" m-1"
                  />
                  <p className="m-0 text-center">
                    <ArrowI className=" inline-block ml-0.5  mr-0.5  w-7 h-7 lg:w-8 lg:h-8 xl:w-10 xl:h-10 align-text-middle" />ごあいさつ</p>
                </button></Link></div>
          </div>
          <div className="basis-1/2  sm:basis-1/3 xl:basis-1/6 max-w-xs">
            <div className="m-1.5">
              <Link to='/therapy/'>
                <button type='button' className="bg-white hover-hover:hover:bg-slate-400  text-left rounded font-semibold shadow-lg">

                  <StaticImage
                    src="../images/seitai-session2.jpg"
                    alt="当院のオステオパシー施術の様子"
                    placeholder="blurred"
                    layout="constrained"
                    className=" m-1"
                  />
                  <p className="m-0 text-center">
                    <ArrowI className=" inline-block ml-0.5  mr-0.5  w-7 h-7 lg:w-8 lg:h-8 xl:w-10 xl:h-10 align-text-middle" />当院の施術</p>
                </button>
              </Link></div>
          </div>



          <div className="basis-1/2  sm:basis-1/3 xl:basis-1/6 max-w-xs">
            <div className="m-1.5">
              <Link to='/faq/'>
                <button type='button' className="bg-white hover-hover:hover:bg-slate-400  text-left rounded font-semibold shadow-lg">

                  <StaticImage
                    src="../images/hatena.jpg"
                    alt="よくある質問"
                    placeholder="blurred"
                    layout="constrained"
                    className=" m-1"
                  />
                  <p className="m-0 text-center">
                    <ArrowI className=" inline-block ml-0.5  mr-0.5  w-7 h-7 lg:w-8 lg:h-8 xl:w-10 xl:h-10 align-text-middle" />よくある質問</p>
                </button></Link></div>
          </div>
          <div className="basis-1/2  sm:basis-1/3 xl:basis-1/6 max-w-xs">
            <div className="m-1.5">
              <Link to='/contact/' >
                <button type='button' className="bg-white hover-hover:hover:bg-slate-400  text-left rounded font-semibold shadow-lg">

                  <StaticImage
                    src="../images/letter.jpg"
                    alt="お問い合わせフォーム"
                    placeholder="blurred"
                    layout="constrained"
                    className=" m-1"
                  />
                  <p className="m-0 text-center">
                    <ArrowI className=" inline-block ml-0.5  mr-0.5  w-7 h-7 lg:w-8 lg:h-8 xl:w-10 xl:h-10 align-text-middle" />お問い合わせ</p>
                </button>
              </Link></div>
          </div>



          <div className="basis-1/2  sm:basis-1/3 xl:basis-1/6 max-w-xs">
            <div className="m-1.5">
              <Link to='/nishiogi/'>
                <button type='button' className="bg-white hover-hover:hover:bg-slate-400  text-left rounded font-semibold shadow-lg">

                  <StaticImage
                    src="../images/nishiogikubo.jpg"
                    alt="ご案内"
                    placeholder="blurred"
                    layout="constrained"
                    className=" m-1"
                  />
                  <p className="m-0 text-center">
                    <ArrowI className=" inline-block ml-0.5  mr-0.5  w-7 h-7 lg:w-8 lg:h-8 xl:w-10 xl:h-10 align-text-middle" />営業案内</p>
                </button></Link></div>
          </div>
          <div className="basis-1/2  sm:basis-1/3 xl:basis-1/6 max-w-xs">
            <div className="m-1.5">
              <Link to='/blog/'>
                <button type='button' className="bg-white hover-hover:hover:bg-slate-400  text-left rounded font-semibold shadow-lg">

                  <StaticImage
                    src="../images/blog.jpg"
                    alt="身体のコラム"
                    placeholder="blurred"
                    layout="constrained"
                    className=" m-1"
                  />
                  <p className="m-0 text-center">
                    <ArrowI className=" inline-block ml-0.5  mr-0.5  w-7 h-7 lg:w-8 lg:h-8 xl:w-10 xl:h-10 align-text-middle" />身体のコラム</p>
                </button>
              </Link></div>
          </div>

        </div>
      </div>

    </>
  )
};

export default ForBeginner



