import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import Globe from "../static/svg/icon-globe.inline.svg";
import CaretForward from "../static/svg/icon-caret-forward.inline.svg";

function SubMenu( props ) {

  const { lang }  = props;
  const { langlink } = props;

  return (

    <div className="bg-yellow-50  text-right pt-0.5 pb-1 px-2 text-0 text-c-sub">

      <Link to={langlink} className="text-[0.5rem] sm:text-sm" >

          <CaretForward className="h-3 w-3 sm:h-4 sm:w-4 inline-block" />
          &nbsp;
            <Globe className="h-3 w-3 sm:h-4 sm:w-4 inline-block  mr-0.5" />
            <span className="underline">
          {(lang === 'ja') ? "English" : "日本語"}
          </span>
      </Link>

    </div>



  );
}

SubMenu.defaultProps = {
  lang: `ja`,
  langlink: `/`,
};

SubMenu.propTypes = {
  lang: PropTypes.string,
  langlink: PropTypes.string,
};


export default SubMenu;