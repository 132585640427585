import PropTypes from "prop-types";
import React from "react";
import {  useStaticQuery,graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"

import Header from "./header"
import HeaderEn from "./headerEn"
import Menu from "./menu"
import SubMenu from"./subMenu"

import FooterNishiogi from "./footerNishiogi"

import BackPain from "../static/svg/backpain.inline.svg"
import ShoulderPain from "../static/svg/shoulder-pain.inline.svg"
import Wavy from "../images/wavy.svg"


const SvgBg = styled.div`

  background-image: url(${Wavy});
`;




function LayoutTop ({ children, lang, pathTop }){

  
  const data = useStaticQuery(graphql`
  query LayoutTopQuery {
    site {
      siteMetadata {
        tel
      }
    }
  }
  `);

  const linkToTopJ =  pathTop || '/top/';

  const langlink =  ( lang === `ja` )
                    ? '/en/' 
                    : linkToTopJ ;

  return (
    <div className="w-full flex flex-col min-h-screen font-body text-gray-900 tracking-wide leading-8 select-none">
      <main
        className="container flex-1 xs:px-4 w-full max-w-screen-2xl mx-auto md:px-8 ">
                  { lang === `ja` 
                    ? <Header langlink='/en/' tel={data.site.siteMetadata.tel} />
                    : <HeaderEn langlink='/' tel={data.site.siteMetadata.tel} />
        }
        { lang === `ja`
        ? <Menu lang={lang} langlink={langlink} pathTop={linkToTopJ} />
        : <Menu lang={lang} langlink={langlink} />
      }
        <SubMenu lang={lang} langlink={langlink}/>

        {/* BODY */}

        <SvgBg className="lp-hero flex max-w-3xl m-auto py-3 px-3 relative">

            <div className="basis-1/6">
            <BackPain className="sm:w-10/12 pt-2 fill-blue-700"/>
            </div>
            <div className="basis-4/6 table">
            <div className="table-cell align-middle"><p className="text-sm mb-0 font-bold  lg:text-xl ">西荻窪 徒歩3分<span  className="xs:hidden"><br /></span>&nbsp;&nbsp;アメリカ式整体 オステオパシー</p><p className="text-center font-bold  lg:text-2xl ">なかなかとれない<span  className="sm:hidden"><br /></span>コリや痛みにお困りの方へ</p></div></div>
            <div className="basis-1/6 table">
                <div className="table-cell align-bottom m-auto pb-2"><ShoulderPain className="xs:w-8/12 fill-blue-800" />
                </div>
            </div>

            </SvgBg>
            <hr className="border-2 border-dotted border-amber-200 mb-4"/>
            <div>
            
            <div className="p-3 max-w-xl m-auto">
            <StaticImage
              src="../images/manga2.jpg"
              alt="オステオパシーで全身調整の説明"
              placeholder="blurred"
              layout="constrained"
            />
            </div>
            {children}
        </div>


        <FooterNishiogi />

      </main>
    </div>


  );
}

LayoutTop.defaultProps = {
  lang: `ja`,
  pathTop: null,
};

LayoutTop.propTypes = {
  children: PropTypes.node.isRequired,
  lang: PropTypes.string,
  pathTop: PropTypes.string,
};


export default LayoutTop;