import React from "react";
import { Link } from "gatsby";

import InformationCircleOutline from "../static/svg/icon-information-circle-outline.inline.svg";
import ChevronDoubleRight from "../static/svg/icon-chevron-double-right.inline.svg";

function InfoLink(  ) {



  return (
    <>
    <hr className="border-1 border border-c-acc my-4"/>
    <div className="my-0.5  text-center pt-2 pb-4 ">
      
      <div className="text-center block">
        <div className=" inline-block text-left">
          <div className="mb-1.5">

            <Link to='/nishiogi/' className="text-lg text-c-sub underline  hover-hover:hover:text-c-main">

              <ChevronDoubleRight className="h-4 w-4 align-middle inline  text-lx pb-0.5 text-c-sub fill-c-sub" />
              ご案内

            </Link>
          </div>

        </div>
      </div>
      <hr className="border-1 border border-c-acc my-4"/>
      


    </div>
    </>

  );
}



export default InfoLink;